import axios from 'axios';
import { RESPONSE_MESSAGE } from '../utils';
import { API_URL } from './utils';

export const HomePageAPI = {
    getPosts: async () => {
        try {
            const response = await axios.get(API_URL + 'get_rss_posts/');
            return response.data.posts;
        } catch (error) {
            RESPONSE_MESSAGE.error(`Ошибка при получении постов! ${error}`);
        }
    },
};
