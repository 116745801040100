import {
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Navigate,
} from 'react-router-dom';
import { ProtectedRoute } from './projected-route';

import 'antd/dist/reset.css';
import App from '../App';
import ErrorPage from '../pages/error-page';
import { CarsPage } from '../pages/cars-page/cars-page';
import { CarPage } from '../pages/car-page/car-page';
import NotFoundPage from '../pages/not-found';
import { LoginPage } from '../pages/login-page/login-page';
import { Logout } from '../pages/logout-page/logout-page';
import { RegisterPage } from '../pages/register-page/register-page';
import { UserPage } from '../pages/user-page/user-page';
import { AddServiceForm } from '../components/service-add-form/service-add-form';
import { EditServiceForm } from '../components/service-edit-form/service-edit-form';
import { AddSheduledServiceForm } from '../components/scheduled_service/scheduled_service_add_form';
import { EditSheduledServiceForm } from '../components/scheduled_service/scheduled_service_edit_form';
import { ServiceExamplePage } from '../pages/service-example-page/service-example-page';
import { HowGetBonusesPage } from '../pages/how-get-bonuses-page/how-get-bonuses-page';
import { HomePage } from '../pages/home-page/home-page';
import { WebcamPage } from '../pages/webcam-example-page/webcam-page';
import { AddReceiptForm } from '../components/service-add-form/add-receipt-form';
import { FinesPage } from '../pages/fines-page/fines-page';

export const Routes = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<App />} errorElement={<ErrorPage />}>
                <Route errorElement={<ErrorPage />}>
                    <Route
                        path="/"
                        element={<Navigate to="home-page/" replace />}
                    />
                    <Route index path="home-page/" element={<HomePage />} />
                    <Route path="cars/" element={<CarsPage />} />
                    <Route path="cars/:carId" element={<CarPage />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="webcam" element={<WebcamPage />} />
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route path="fines/" element={<FinesPage />} />
                        <Route
                            path="services/add/"
                            element={<AddServiceForm />}
                        />
                        <Route
                            path="services/add-receipt/"
                            element={<AddReceiptForm />}
                        />
                        <Route
                            path="services/examples/"
                            element={<ServiceExamplePage />}
                        />
                        <Route
                            path="services/:serviceId/edit/"
                            element={<EditServiceForm />}
                        />
                        <Route
                            path="scheduled-service/add/"
                            element={<AddSheduledServiceForm />}
                        />
                        <Route
                            path="scheduled-service/:serviceId/edit/"
                            element={<EditSheduledServiceForm />}
                        />
                        <Route path="user" element={<UserPage />} />
                        <Route
                            path="how-get-bonuses"
                            element={<HowGetBonusesPage />}
                        />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                    // TODO проверка маршрутов
                </Route>
            </Route>
        )
    );

    return <RouterProvider router={router} />;
};
