import { FC, MouseEvent, ReactNode } from 'react';
import styles from './orange-button.module.less';

export interface IOrangeButtonProps {
    children: ReactNode;
    handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
    type?: 'submit' | 'reset' | 'button' | undefined;
}

export const OrangeButton: FC<IOrangeButtonProps> = ({
    children,
    handleClick,
    type,
}) => {
    return (
        <button
            className={styles.edit__button}
            onClick={handleClick}
            type={type}
        >
            {children}
        </button>
    );
};
