import styles from './home-cars-list.module.less';
import { NavLink } from 'react-router-dom';
import { CustomImage } from '../../components/custom-image/custom-image';
import { DEFAULT_CATALOG_TYPE } from '../../variables';

export const HomeCarsList = ({ cars }) => {
    return (
        <div className={styles.cars}>
            {cars.map((car) => (
                <div className={styles.car} key={car.id}>
                    <NavLink
                        to={`/cars/${car.id}/?catalog_type=${DEFAULT_CATALOG_TYPE}`}
                    ></NavLink>
                    <div className={styles.car_image}>
                        <CustomImage
                            src={car.photo}
                            backgroundPosition={'50% 70%'}
                        />
                    </div>
                    <div>
                        <p className={styles.car_text}>
                            {car.short_description}
                        </p>
                    </div>

                    <div className={styles.car_prices}>
                        <p className={styles.car_price}>
                            <b>Цена: </b>
                            {car.price} ₽
                        </p>
                        <p className={styles.card_price}>
                            <b>Аренда с выкупом: </b>
                            {car.daily_rent} ₽/дн.
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};
