import React, { useEffect, useContext } from 'react';
import { Layout } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { UsersContext } from '../../context/user-context/user-context';
import { Preloader } from '../preloader';
import { getStorageUser } from '../../utils';
import styles from './template.module.less';
import { Float } from '../float/float';
const { Header, Content, Footer } = Layout;

export interface TemplateProps {
    children: React.ReactNode;
}

export const Template: React.FC<TemplateProps> = ({ children }) => {
    const deleteCurrentUser = useContext(UsersContext)?.deleteCurrentUser;
    const setUser = useContext(UsersContext)?.setUser;
    const user = getStorageUser();
    const token: string | null = localStorage.getItem('token');

    useEffect(() => {
        if (!user && token && setUser) {
            setUser();
        }
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key === 'token' && setUser) {
                setUser();
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [setUser, token, user]);

    if (!deleteCurrentUser) {
        return <Preloader />;
    }

    return (
        <Layout className={styles.layout}>
            <Header className={styles.layout__header}>
                <div className={styles.header__content}>
                    <NavLink
                        to={'/home-page/'}
                        className={styles.header__logo}
                    />
                    <div className={styles.header_link__wrapper}>
                        {user?.fullname ? (
                            <>
                                <NavLink
                                    to={'/user/'}
                                    className={styles.header__link}
                                >
                                    {user.fullname}
                                </NavLink>
                                <NavLink
                                    to={'/logout'}
                                    className={styles.header__link}
                                    onClick={() => deleteCurrentUser()}
                                >
                                    <UserOutlined /> Выйти
                                </NavLink>
                            </>
                        ) : (
                            <NavLink
                                to={'login'}
                                className={styles.header__link}
                            >
                                <UserOutlined />
                                Войти
                            </NavLink>
                        )}
                    </div>
                </div>
            </Header>
            <Content className={styles.content}>{children}</Content>
            <Footer className={styles.footer}>
                <div className={styles.footer__content}>
                    <span>
                        <b>Поддержка: </b>
                        <a href="mailto:autocar71@yandex.ru">
                            autocar71@yandex.ru
                        </a>
                    </span>
                </div>
            </Footer>
            <Float />
        </Layout>
    );
};
