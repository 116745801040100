import styles from './posts-list.module.less';
import { CustomImage } from '../../components/custom-image/custom-image';
import { PlayCircleOutlined } from '@ant-design/icons';
export const PostsList = ({ posts }) => {
    return (
        <div className={styles.posts}>
            {posts.map((post) => (
                <div className={styles.post} key={post.uid}>
                    <div>
                        <div className={styles.post_image}>
                            {post.mediatype === 'video' && (
                                <a href={post.mediadata} target="_blank">
                                    <PlayCircleOutlined />
                                </a>
                            )}
                            <CustomImage
                                src={post.image}
                                backgroundPosition={'50% 40%'}
                            />
                        </div>
                        <p className={styles.post_text}>{post.descr}</p>
                    </div>
                    <a
                        href={post.url}
                        target="_blank"
                        className={styles.post_link}
                    >
                        Читать больше
                    </a>
                </div>
            ))}
        </div>
    );
};
