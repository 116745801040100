import { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Upload, Image, Select } from 'antd';
import { CarAPI } from '../../api/cars-api';
import { Preloader } from '../preloader';
import { getBase64, getStorageUser } from '../../utils';
import styles from './service-add-form.module.less';
import { OrangeButton } from '../buttons/orange-button';
import { ServiceAPI } from '../../api/services-api';
import { allowedFormats } from '../../variables';
import { uploadFormatValidate } from '../../validators';

const { TextArea } = Input;

const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </button>
);
const RENT_PAYMENT = 'Оплата аренды';
export const AddReceiptForm = () => {
    const token = localStorage.getItem('token');
    const user = getStorageUser();

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [rents, setRents] = useState([]);
    const [licencePlates, setLicencePlates] = useState([]);

    const [fileList, setFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);

    useEffect(() => {
        const fetchServicesData = async () => {
            let rents;
            let licencePlates;
            if (user?.role === 'CLIENT') {
                rents = await CarAPI.getRents();
            } else {
                licencePlates = await CarAPI.getLicencePlates();
            }
            if (licencePlates) {
                setLicencePlates(licencePlates);
            }
            if (rents) {
                setRents(rents);
            }
        };
        fetchServicesData();
    }, [token, user?.role]);

    const onFinish = async (values) => {
        const files = [];

        for (const file of fileList) {
            if (file.originFileObj) {
                const base64 = await getBase64(file.originFileObj);
                files.push({ file: base64 });
            }
        }
        const data = {
            car: values.carLicensePlate,
            service: RENT_PAYMENT,
            comment: values.serviceComment ?? '',
            files,
        };
        await ServiceAPI.addService(data);
        setFileList([]);
        form.resetFields();
        navigate('/user/');
    };

    if (
        (!rents || !rents.length) &&
        (!licencePlates || !licencePlates.length)
    ) {
        return <Preloader />;
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        form.setFieldsValue({
            images: newFileList.map((file) => file.originFileObj),
        });
    };

    return (
        <div className={styles.page__wrapper}>
            <h1>Добавить чек</h1>
            <Form
                form={form}
                onFinish={onFinish}
                className={styles.form}
                initialValues={{
                    carLicensePlate:
                        rents[0]?.car_license_plate || licencePlates[0],
                }}
            >
                <h3>Номер автомобиля</h3>
                <Form.Item
                    className={styles.form__item}
                    name="carLicensePlate"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите автомобиль',
                        },
                    ]}
                >
                    {user?.role === 'CLIENT' ? (
                        <Select size="large">
                            {rents &&
                                rents.map((rent) => (
                                    <Select.Option
                                        value={rent.car_license_plate}
                                        key={rent.car_id}
                                    >
                                        {rent.car_license_plate}
                                    </Select.Option>
                                ))}
                        </Select>
                    ) : (
                        <Select size="large">
                            {licencePlates &&
                                licencePlates.map((licencePlate, i) => (
                                    <Select.Option value={licencePlate} key={i}>
                                        {licencePlate}
                                    </Select.Option>
                                ))}
                        </Select>
                    )}
                </Form.Item>
                <h3>Комментарий</h3>
                <Form.Item className={styles.form__item} name="serviceComment">
                    <TextArea
                        size="large"
                        rows={4}
                        autoSize={{ minRows: 4, maxRows: 8 }}
                    />
                </Form.Item>
                <h3>Загрузите файлы</h3>
                <Form.Item
                    className={styles.form__item__upload}
                    name="images"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста добавьте фото.',
                        },
                        {
                            validator: () =>
                                uploadFormatValidate(fileList, allowedFormats),
                        },
                    ]}
                >
                    <div>
                        <Upload
                            className={styles.upload}
                            listType="picture-card"
                            beforeUpload={() => false}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            {fileList.length >= 5 ? null : uploadButton}
                        </Upload>
                        {previewImage && (
                            <Image
                                wrapperStyle={{ display: 'none' }}
                                preview={{
                                    visible: previewOpen,
                                    onVisibleChange: (visible) =>
                                        setPreviewOpen(visible),
                                    afterOpenChange: (visible) =>
                                        !visible && setPreviewImage(''),
                                }}
                                src={previewImage}
                            />
                        )}
                    </div>
                </Form.Item>
                <Form.Item className={styles.button__item}>
                    <OrangeButton
                        children="Отправить"
                        handleClick={() => onFinish}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
