import { useEffect, useState } from 'react';
import { Divider } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import styles from './services-list.module.less';
import { OrangeButton } from '../buttons/orange-button';
import { ServiceFilesList } from '../service-files-list/service-files-list';
import { ServiceAPI } from '../../api/services-api';
import { Preloader } from '../preloader';

export const ServicesList = ({ services }) => {
    const [currentServices, setCurrentServices] = useState([]);
    useEffect(() => {
        setCurrentServices(services);
    }, [services]);

    const deleteService = async (id) => {
        await ServiceAPI.deleteService(id);
        setCurrentServices((prevServices) =>
            prevServices.filter((service) => service.id !== id)
        );
    };

    if (!currentServices.length) {
        return <Preloader />;
    }
    return (
        <div>
            {currentServices.length !== 0 && <h1>Сервисы</h1>}

            <ul className={styles.cards}>
                {currentServices.map((service) => (
                    <li className={styles.cards__item} key={service.id}>
                        <article className={styles.card}>
                            <div className={styles.card__image}>
                                {service.files ? (
                                    <ServiceFilesList
                                        fileData={service.files}
                                    />
                                ) : (
                                    <div style={{ height: '100px' }}>
                                        <h1>Нет фото</h1>
                                    </div>
                                )}
                            </div>
                            <Divider className={styles.divider} />
                            <div className={styles.card__content}>
                                <h3 className={styles.card__title}>
                                    <b>{service.service}</b>
                                </h3>
                                <p className={styles.card__descr}>
                                    <span>
                                        <b>Выполнено:</b>{' '}
                                        {service.date_service
                                            ? service.date_service.toLocaleString()
                                            : 'ожидается'}
                                    </span>
                                </p>
                                <p className={styles.card__descr}>
                                    <b>Номер Машины: </b>
                                    {service.car_license_plate}
                                </p>
                                {service.odometer_indicate && (
                                    <p className={styles.card__descr}>
                                        <b>Пройденное расстояние(км): </b>
                                        {service.odometer_indicate}
                                    </p>
                                )}
                                {service.comment && (
                                    <div className={styles.card__descr}>
                                        {service.comment}
                                    </div>
                                )}

                                <div className={styles.card__btn}>
                                    <OrangeButton
                                        children={
                                            <>
                                                <EditOutlined /> Удалить сервис
                                            </>
                                        }
                                        handleClick={() =>
                                            deleteService(service.id)
                                        }
                                    />
                                </div>
                                {/* <div className={styles.card__btn}>
                                    <OrangeButton
                                        children={
                                            <>
                                                <EditOutlined /> Изменить сервис
                                            </>
                                        }
                                        handleClick={() =>
                                            navigate(
                                                `/services/${service.id}/edit/`
                                            )
                                        }
                                    />
                                </div> */}
                            </div>
                        </article>
                    </li>
                ))}
            </ul>
        </div>
    );
};
