import { FC } from 'react';
import { Select } from 'antd';

interface ISelectServicesProsp {
    services: string[];
}

export const SelectServicesNames: FC<ISelectServicesProsp> = ({ services }) => {
    return (
        <Select defaultValue={services[1]} size="large">
            {services.map((service) => (
                <Select.Option value={service} key={service}>
                    {service}
                </Select.Option>
            ))}
        </Select>
    );
};
