// App.js

import React, { useState } from 'react';
import WebcamVideoCapture from './webcam';
import WebcamImage from './webcam-image';
import { WebcamVideo } from './webcam-video';
import WebcamCapture from './webcam-gemini';
// import CameraComponent from './CameraComponent';

export const WebcamPage = () => {
    const [allCaptures, setAllCaptures] = useState([]);
    const [mediaType, setMediaType] = useState('photo'); // По умолчанию - фото

    const handleCapture = (capture, type) => {
        setAllCaptures((prevCaptures) => ({
            ...prevCaptures,
            [type]: [...(prevCaptures[type] || []), capture],
        }));
    };

    return (
        <div>
            <button onClick={() => setMediaType('photo')}>Режим фото</button>
            <button onClick={() => setMediaType('video')}>Режим видео</button>
            <WebcamCapture mediaType={mediaType} onCapture={handleCapture} />
            {/* Работа с allCaptures */}
        </div>
    );
    // return (
    // <div className="App">
    //     <h1>React Webcam Recording</h1>
    //     <WebcamImage />
    //     <WebcamVideo />

    //  <WebcamVideoCapture />

    //     <CameraComponent />

    // </div>
    // );
};
