import { FC, useEffect, useState } from 'react';
import styles from './how-get-bonuses-page.module.less';
import { UserAPI } from '../../api/user-api';
import { IBonusActivities } from '../../types/user-context-types';

export const HowGetBonusesPage: FC = () => {
    const [bonusActivities, setBonusActivities] = useState<IBonusActivities[]>(
        []
    );
    useEffect(() => {
        const fetchBonusActivitiesData = async () => {
            const activities = await UserAPI.getBonusActivities();

            if (activities) {
                setBonusActivities(activities);
            }
        };
        fetchBonusActivitiesData();
    }, []);
    return (
        <div className={styles.page__wrapper}>
            <h1 className={styles.page__header}>Бонусы</h1>
            <h2 className={styles.page__sub_header}>Как получить</h2>
            <hr className={styles.divider} />
            <div className={styles.bonus__conditions__wrapper}>
                {bonusActivities.map((bonusActivity) => (
                    <div key={bonusActivity.id}>
                        <h3>Активность: {bonusActivity.name}</h3>
                        <h4>Условия выполнения: </h4>
                        <div>
                            <p>
                                <ul>
                                    {bonusActivity.required_bonuses
                                        .split('-')
                                        .map(
                                            (article) =>
                                                article && <li>{article}</li>
                                        )}
                                </ul>
                            </p>
                        </div>
                        <div>
                            <p>
                                <div>
                                    <b>Начисления:</b>{' '}
                                    <span className={styles.bonus_size}>
                                        {bonusActivity.bonus_points}
                                    </span>{' '}
                                    бонусов
                                </div>
                            </p>
                        </div>

                        <hr className={styles.divider} />
                    </div>
                ))}
            </div>
            <h2 className={styles.page__sub_header}>
                Как списать и на что потратить.
            </h2>
            <ul>
                <li>Один бонус - один рубль</li>
                <li>Бонусы можно потратить на аренду</li>
                <li>
                    {' '}
                    Бонусы можно списать позвонив по нашему телефону или написав
                    на почту.
                </li>
            </ul>
        </div>
    );
};
