// src/components/TrafficFines.js
import React from 'react';
import { Collapse, List, Typography, Row, Col, Button } from 'antd';
import moment from 'moment';
import styles from './fine.module.less';
import GeoMarker from '../geo-marker/geo-marker';
import { CopyButton } from '../copy-button/copy-button';

const { Panel } = Collapse;
const { Text } = Typography;

const TrafficFines = ({ fines }) => {
    return (
        <Collapse accordion>
            {fines.map((fine, index) => {
                const discountDate = moment(fine.gis_discount_uptodate);
                const now = moment();
                const isDiscountActive = discountDate.isSameOrAfter(now, 'day');

                return (
                    <Panel
                        key={index}
                        header={
                            <Row
                                gutter={16}
                                className={
                                    fine.gis_status === 'payed'
                                        ? styles.border_shadow__green
                                        : styles.border_shadow__red
                                }
                            >
                                <Col xs={24} sm={24} md={6}>
                                    <div className={styles.min_width}>
                                        <Text strong>
                                            {moment(fine.offense_date).format(
                                                'DD.MM.YYYY'
                                            )}{' '}
                                            {fine.offense_time}
                                        </Text>{' '}
                                        <Text>
                                            Место{' '}
                                            <GeoMarker
                                                latitude={parseFloat(
                                                    fine.offense_latitude ||
                                                        55.7558
                                                )}
                                                longitude={parseFloat(
                                                    fine.offense_longitude ||
                                                        37.6173
                                                )}
                                            />
                                        </Text>
                                    </div>
                                </Col>

                                <Col xs={24} sm={24} md={12}>
                                    <Text strong>УИН {fine.bill_id}</Text>
                                    <CopyButton
                                        buttonStyle={{
                                            fontSize: '30px',
                                            marginLeft: '5px',
                                            color: 'gray',
                                            backgroundColor: '#fff2e8',
                                            overflow: 'hidden',
                                        }}
                                        text={fine.bill_id}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={6}>
                                    {isDiscountActive ? (
                                        <Text>
                                            <strong>
                                                {
                                                    fine.pay_bill_amount_with_discount
                                                }{' '}
                                                ₽{' '}
                                            </strong>
                                            - {fine.gis_discount}% до{' '}
                                            {moment(
                                                fine.gis_discount_uptodate
                                            ).format('DD.MM')}
                                        </Text>
                                    ) : (
                                        <Text>
                                            <strong>
                                                {fine.pay_bill_amount} ₽
                                            </strong>
                                        </Text>
                                    )}
                                </Col>
                            </Row>
                        }
                    >
                        <List size="small">
                            <List.Item>
                                <Text strong>Нарушение:</Text>&nbsp;
                                <Text>
                                    {moment(fine.offense_date).format(
                                        'DD.MM.YYYY HH:mm'
                                    )}
                                </Text>
                            </List.Item>
                            <List.Item>
                                <Text strong>Постановление:</Text>&nbsp;
                                <Text>
                                    {moment(fine.pay_bill_date).format(
                                        'DD.MM.YYYY'
                                    )}
                                </Text>
                            </List.Item>
                            <List.Item>
                                <Text strong>Место нарушения:</Text>&nbsp;
                                <Text>{fine.offense_location}</Text>
                            </List.Item>
                            <List.Item>
                                <Text strong>
                                    Координаты:{' '}
                                    <GeoMarker
                                        latitude={parseFloat(
                                            fine.offense_latitude || 55.7558
                                        )}
                                        longitude={parseFloat(
                                            fine.offense_longitude || 37.6173
                                        )}
                                        color="gray"
                                    />
                                </Text>
                            </List.Item>
                            <List.Item>
                                <Text strong>Администратор штрафа:</Text>&nbsp;
                                <Text>{fine.gis_podrazdelenie}</Text>
                            </List.Item>
                            <List.Item>
                                <Text strong>Статья:</Text>&nbsp;
                                <Text>{fine.offense_article_number}</Text>
                            </List.Item>
                            <List.Item>
                                <Text strong>Отвественный:</Text>&nbsp;
                                <Text>{fine.gis_send_to}</Text>
                            </List.Item>
                            {/* <Row style={{ marginTop: '10px' }}>
                                <Col span={24}>
                                    <Button type="primary">Оплатить</Button>
                                </Col>
                            </Row> */}
                        </List>
                    </Panel>
                );
            })}
        </Collapse>
    );
};

export default TrafficFines;
