import { CloseCircleOutlined } from '@ant-design/icons';
import styles from './webcam.module.less';
import { Image } from 'antd';

export const WebcamPreviewsList = ({ onRemove, mediaList }) => {
    return (
        <div className={styles.preview_wrapper}>
            {['photo', 'video'].map((type) =>
                mediaList[type].map((media, index) => (
                    <div key={index} className={styles.preview}>
                        <div className={styles.preview_img}>
                            {type === 'photo' && (
                                <Image src={media} alt={`Фото ${index + 1}`} />
                            )}
                        </div>

                        {type === 'video' && <video src={media} controls />}
                        <div className={styles.button_wrapper}>
                            <CloseCircleOutlined
                                className={styles.delete_button_dark}
                                onClick={() => onRemove(media, type)}
                            />
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};
