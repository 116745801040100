import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Select } from 'antd';
import { CarAPI } from '../../api/cars-api';
import { Preloader } from '../preloader';
import { getStorageUser } from '../../utils';
import styles from './service-add-form.module.less';
import { OrangeButton } from '../buttons/orange-button';
import { ServiceAPI } from '../../api/services-api';
import { WebcamPreviewsList } from '../webcam/webcam-preview-list';
import { WebcamCapture } from '../webcam/webcam';
import { SelectServicesNames } from './select-service-names';

const { TextArea } = Input;
export const AddServiceForm = () => {
    const token = localStorage.getItem('token');
    const user = getStorageUser();

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [rents, setRents] = useState([]);
    const [licencePlates, setLicencePlates] = useState([]);
    const [selectedService, setSelectedService] = useState(null);

    const [allCaptures, setAllCaptures] = useState({
        photo: [],
        video: [],
    });
    const [showCamera, setShowCamera] = useState(false);
    const onCancel = () => {
        setShowCamera(false);
    };

    const CHANGE_OIL = 'Замена масла';
    const TECHNICAL_INSPECTION = 'Тех. осмотр';
    const serviceNames = [CHANGE_OIL, TECHNICAL_INSPECTION];

    const handleCapture = (capture, type) => {
        let lastVideoCount = -5;
        // if (type === 'video') {
        //     lastVideoCount = -2;
        // }
        setAllCaptures((prevCaptures) => ({
            ...prevCaptures,
            [type]: [...(prevCaptures[type] || []), capture].slice(
                lastVideoCount
            ),
        }));
    };

    const handleRemoveMedia = (media, type) => {
        setAllCaptures((prevMedia) => ({
            ...prevMedia,
            [type]: prevMedia[type].filter((item) => item !== media),
        }));
    };

    useEffect(() => {
        const fetchServicesData = async () => {
            const services = await ServiceAPI.getAllServicesNames();
            let rents;
            let licencePlates;
            if (user?.role === 'CLIENT') {
                rents = await CarAPI.getRents();
            } else {
                licencePlates = await CarAPI.getLicencePlates();
            }
            if (licencePlates) {
                setLicencePlates(licencePlates);
            }
            if (rents) {
                setRents(rents);
            }
            if (services) {
                setSelectedService(services[0].name);
                setServices(services);
            }
        };
        fetchServicesData();
    }, [token, user?.role]);

    const onFinish = async (values) => {
        const files = [];

        for (const file of allCaptures.photo) {
            files.push({ file: file });
        }

        const data = {
            car: values.carLicensePlate,
            service: values.serviceName,
            comment: values.serviceComment ?? '',
            odometer_indicate: values.odometerIndicate,
            files,
        };
        await ServiceAPI.addService(data);
        setAllCaptures({
            photo: [],
            video: [],
        });
        form.resetFields();
        navigate('/user/');
    };

    if (
        !services ||
        !services.length ||
        ((!rents || !rents.length) && (!licencePlates || !licencePlates.length))
    ) {
        return <Preloader />;
    }
    return (
        <div className={styles.page__wrapper}>
            <h1>Добавление сервиса</h1>
            <Form
                form={form}
                onFinish={onFinish}
                className={styles.form}
                initialValues={{
                    carLicensePlate:
                        rents[0]?.car_license_plate || licencePlates[0],
                    serviceName: serviceNames[1],
                }}
            >
                <h3>Номер автомобиля</h3>
                <Form.Item
                    className={styles.form__item}
                    name="carLicensePlate"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите автомобиль',
                        },
                    ]}
                >
                    {user?.role === 'CLIENT' ? (
                        <Select size="large">
                            {rents &&
                                rents.map((rent) => (
                                    <Select.Option
                                        value={rent.car_license_plate}
                                        key={rent.car_id}
                                    >
                                        {rent.car_license_plate}
                                    </Select.Option>
                                ))}
                        </Select>
                    ) : (
                        <Select size="large">
                            {licencePlates &&
                                licencePlates.map((licencePlate, i) => (
                                    <Select.Option value={licencePlate} key={i}>
                                        {licencePlate}
                                    </Select.Option>
                                ))}
                        </Select>
                    )}
                </Form.Item>
                <h3>Название сервиса</h3>
                <Form.Item className={styles.form__item} name="serviceName">
                    <SelectServicesNames services={serviceNames} />
                </Form.Item>
                {(selectedService === CHANGE_OIL ||
                    selectedService === TECHNICAL_INSPECTION) && (
                    <>
                        <h3>Пройденное расстояние(км)</h3>
                        <Form.Item
                            className={styles.form__item}
                            name="odometerIndicate"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Должно быть число от 1 до 1000000',
                                    pattern: new RegExp(/^[0-9]{1,7}$/),
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </>
                )}
                <h3>Комментарий</h3>
                <Form.Item className={styles.form__item} name="serviceComment">
                    <TextArea
                        size="large"
                        rows={4}
                        autoSize={{ minRows: 4, maxRows: 8 }}
                    />
                </Form.Item>
                <h3>Сделать фото</h3>
                <Form.Item className={styles.form__item__upload} name="images">
                    <div className={styles.webcam_wrapper}>
                        <WebcamPreviewsList
                            onRemove={handleRemoveMedia}
                            mediaList={allCaptures}
                        />
                        {!showCamera ? (
                            <div className={styles.add_media_button_wrapper}>
                                <button
                                    type="button"
                                    className={styles.add_media_button}
                                    onClick={() => setShowCamera(true)}
                                >
                                    Добавить фото
                                </button>
                            </div>
                        ) : (
                            <WebcamCapture
                                onCancel={onCancel}
                                onCapture={handleCapture}
                                onRemove={handleRemoveMedia}
                                mediaList={allCaptures}
                            />
                        )}
                    </div>
                </Form.Item>
                <Form.Item className={styles.button__item}>
                    <OrangeButton
                        children="Отправить"
                        handleClick={() => form.submit()}
                        type="button"
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
