import axios from 'axios';
import {
    IAddServiceData,
    IAddServicesResponse,
    IAllServicesName,
    IAllTechnicalServices,
    IService,
} from '../types/service-types';
import { RESPONSE_MESSAGE } from '../utils';
import {
    IAddScheduledServicesData,
    IAddScheduledServicesResponse,
    IScheduledService,
    IScheduledServiceError,
} from '../types/scheduled-service-types';
import { API_URL, AUTH_HEADERS } from './utils';

export const ServiceAPI = {
    getAllServicesNames: async () => {
        try {
            const response = await axios.get<IAllServicesName[]>(
                API_URL + 'services/get_name_services/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении имён сервисов!');
        }
    },
    getAllTechnicalServices: async () => {
        try {
            const response = await axios.get<IAllTechnicalServices[]>(
                API_URL + 'services/get_services/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении имён сервисов!');
        }
    },
    getServices: async () => {
        try {
            const response = await axios.get<IService[]>(
                API_URL + 'services/',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении сервисов');
        }
    },
    getService: async (serviceId: number) => {
        try {
            const response = await axios.get<IService>(
                `${API_URL}services/${serviceId}/`,
                {
                    headers: AUTH_HEADERS,
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при получении сервиса');
        }
    },
    addService: async (data: IAddServiceData) => {
        try {
            const response = await axios.post<IAddServicesResponse>(
                API_URL + 'services/',
                data
            );
            RESPONSE_MESSAGE.success('Сервис успешно добавлен!');
            return response.data;
        } catch (error) {
            const serviceError = error as IScheduledServiceError;
            if (serviceError.response.data.non_field_errors) {
                RESPONSE_MESSAGE.error(
                    serviceError.response.data.non_field_errors
                );
            } else {
                RESPONSE_MESSAGE.error('Ошибка при добавлении сервиса!');
            }
        }
    },
    editService: async (serviceId: number, data: IAddServiceData) => {
        try {
            const response = await axios.patch<IAddServicesResponse>(
                `${API_URL}services/${serviceId}/`,
                data
            );
            RESPONSE_MESSAGE.success('Сервис успешно изменён!');
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при изменении сервиса!');
        }
    },
    deleteService: async (serviceId: number) => {
        try {
            const response = await axios.delete(
                `${API_URL}services/${serviceId}/`
            );
            RESPONSE_MESSAGE.success('Сервис успешно удалён!');
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error('Ошибка при удалении сервиса!');
        }
    },
    getScheduledServices: async () => {
        try {
            const response = await axios.get<IScheduledService[]>(
                `${API_URL}scheduled_service/`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error(
                'Ошибка при получении запланированных сервисов'
            );
        }
    },
    getScheduledService: async (serviceId: number) => {
        try {
            const response = await axios.get<IScheduledService>(
                `${API_URL}scheduled_service/${serviceId}/`,
                {
                    headers: AUTH_HEADERS,
                }
            );
            return response.data;
        } catch (error) {
            RESPONSE_MESSAGE.error(
                'Ошибка при получении запланированного сервиса'
            );
        }
    },
    addScheduledService: async (data: IAddScheduledServicesData) => {
        try {
            const response = await axios.post<IAddScheduledServicesResponse>(
                API_URL + 'scheduled_service/',
                data
            );
            RESPONSE_MESSAGE.success(
                'Запланированный сервис успешно добавлен!'
            );
            return response.data;
        } catch (error) {
            const scheduledServiceError = error as IScheduledServiceError;
            if (scheduledServiceError.response.data.non_field_errors) {
                RESPONSE_MESSAGE.error(
                    scheduledServiceError.response.data.non_field_errors
                );
            } else {
                RESPONSE_MESSAGE.error(
                    'Ошибка при изменении запланированного сервиса!'
                );
            }
        }
    },
    editScheduledService: async (
        serviceId: number,
        data: IAddScheduledServicesData
    ) => {
        try {
            const response = await axios.patch<IAddScheduledServicesResponse>(
                `${API_URL}scheduled_service/${serviceId}/`,
                data
            );
            RESPONSE_MESSAGE.success('Запланированный сервис успешно изменён!');
            return response.data;
        } catch (error) {
            const scheduledServiceError = error as IScheduledServiceError;
            if (scheduledServiceError.response.data.non_field_errors) {
                RESPONSE_MESSAGE.error(
                    scheduledServiceError.response.data.non_field_errors
                );
            } else {
                RESPONSE_MESSAGE.error(
                    'Ошибка при изменении запланированного сервиса!'
                );
            }
        }
    },
};
