import React from 'react';
import { Tooltip } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';

const GeoMarker = ({
    latitude,
    longitude,
    fontSize = '20px',
    color = 'gray',
}) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;

    return (
        <Tooltip title={`Координаты: ${latitude}, ${longitude}`}>
            <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
                <EnvironmentOutlined
                    style={{ fontSize: fontSize, color: color }}
                />
            </a>
        </Tooltip>
    );
};

export default GeoMarker;
