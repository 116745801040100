import React, { useEffect, useState } from 'react';
import { UserAPI } from '../../api/user-api';
import TrafficFines from '../../components/fine/traffic-fines';
import styles from './fines-page.module.css';

const MarkerExample = () => {
    return (
        <div className={styles.markers_wrapper}>
            <div className={`${styles.border_shadow__green} ${styles.padding}`}>
                {'> Оплаченый штраф'}
            </div>
            <div className={`${styles.border_shadow__red} ${styles.padding}`}>
                {'> Неоплаченый штраф'}
            </div>
        </div>
    );
};

export const FinesPage = () => {
    const [fines, setFines] = useState([]);

    const fetchFines = async () => {
        const fines = await UserAPI.getFines();
        delete fines.sum_of_fines;
        setFines(
            Object.values(fines).sort((a, b) =>
                a.gis_status.localeCompare(b.gis_status)
            ) ?? []
        );
    };
    useEffect(() => {
        fetchFines();
    }, []);
    return (
        <>
            <h1 className={styles.fines_page_header}>Страница штрафов</h1>
            <MarkerExample />
            {fines.length > 0 && (
                <div>
                    <TrafficFines fines={fines} />
                </div>
            )}
        </>
    );
};
