import React, { useState, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Webcam from 'react-webcam';
import {
    CameraOutlined,
    CheckCircleOutlined,
    PlaySquareOutlined,
} from '@ant-design/icons';
import { getBase64 } from '../../utils';
import styles from './webcam.module.less';

const videoConstraints = {
    facingMode: 'user',
};

const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </button>
);

function WebcamCapture({ mediaType, onCapture }) {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturedImages, setCapturedImages] = useState([]);
    const [capturingVideo, setCapturingVideo] = useState(false);

    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);

    const handlePreview = async (file) => {
        // if (!file.url && !file.preview) {
        //     file.preview = await getBase64(file.originFileObj);
        // }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    // const handleChange = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    //     form.setFieldsValue({
    //         images: newFileList.map((file) => file.originFileObj),
    //     });
    // };

    const capturePhoto = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setCapturedImages((prevImages) => [...prevImages, imageSrc].slice(-5)); // Сохраняем до 5 фото
        onCapture(imageSrc, mediaType);
    }, [webcamRef, mediaType, onCapture]);

    const captureVideoStart = () => {
        setCapturingVideo(true);
        const mediaRecorder = new MediaRecorder(webcamRef.current.stream, {
            mimeType: 'video/webm', // Выберите подходящий mime type
        });

        mediaRecorderRef.current = mediaRecorder;

        let recordedChunks = [];
        mediaRecorder.ondataavailable = (e) => {
            recordedChunks.push(e.data);
        };

        mediaRecorder.onstop = () => {
            const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
            const videoUrl = URL.createObjectURL(videoBlob);
            setCapturingVideo(false);
            onCapture(videoUrl, mediaType);
        };

        mediaRecorder.start();
    };

    const captureVideoStop = () => {
        setCapturingVideo(false);
        mediaRecorderRef.current.stop();
    };

    return (
        <div className={styles.webcam_wrapper}>
            <Webcam
                audio={mediaType === 'video'} // Включаем звук только для видео
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
            />
            {mediaType === 'photo' && (
                <button onClick={capturePhoto}>
                    <CameraOutlined /> Сделать фото
                    {/* <FontAwesomeIcon icon={faCamera} /> Сделать фото */}
                </button>
            )}
            {mediaType === 'video' && (
                <>
                    <button
                        onClick={captureVideoStart}
                        disabled={capturingVideo}
                    >
                        <PlaySquareOutlined /> Начать запись
                    </button>
                    <button
                        onClick={captureVideoStop}
                        disabled={!capturingVideo}
                    >
                        <CheckCircleOutlined /> Остановить запись
                    </button>
                </>
            )}
            {/* Вывод превью */}
            {/* <div>
                <Upload
                    className={styles.upload}
                    listType="picture-card"
                    beforeUpload={() => false}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {fileList.length >= 5 ? null : uploadButton}
                </Upload>
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) =>
                                setPreviewOpen(visible),
                            afterOpenChange: (visible) =>
                                !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
            </div> */}
            {capturedImages.map((image, index) => (
                <>
                    <div onClick={() => handlePreview(image)}>
                        Показать превью
                    </div>
                    <img
                        key={index}
                        src={image}
                        alt={`Фото ${index + 1}`}
                        style={{ width: 100, margin: 5 }}
                    />
                </>
            ))}
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                            !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </div>
    );
}

export default WebcamCapture;
