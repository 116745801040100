// Компонент в разработке не понятно пока что с ним делать
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Input, Select, Upload, Image } from 'antd';
import { Preloader } from '../preloader';
import {
    fileFormat,
    getBase64,
    isInteger,
    openFileInNewTab,
} from '../../utils';
import styles from './service-edit-form.module.less';
import { OrangeButton } from '../buttons/orange-button';
import { ServiceAPI } from '../../api/services-api';
import { uploadFormatValidate } from '../../validators';
import { allowedFormats } from '../../variables';
import { WebcamPreviewsList } from '../webcam/webcam-preview-list';
import { WebcamCapture } from '../webcam/webcam';
import { SelectServicesNames } from '../service-add-form/select-service-names';
const { TextArea } = Input;

const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </button>
);

const CHANGE_OIL = 'Замена масла';
const TECHNICAL_INSPECTION = 'Тех. осмотр';
const serviceNames = [CHANGE_OIL, TECHNICAL_INSPECTION];

export const EditServiceForm = () => {
    const [form] = Form.useForm();
    const { serviceId } = useParams();
    const navigate = useNavigate();

    const [currentService, setCurrentService] = useState(null);
    const [services, setServices] = useState([]);
    const [selectedService, setSelectedService] = useState(null);

    const [fileList, setFileList] = useState([]);
    const [removeList, setRemoveList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [allCaptures, setAllCaptures] = useState({
        photo: [],
        video: [],
    });

    const changeFiles = (files) => {
        if (files) {
            return files.map((file) => {
                return {
                    uid: file.id.toString(),
                    url: file.file,
                    name: `file-${file.id}.${file.file.split('.').pop()?.toLowerCase()}`,
                    size: file.file_size,
                };
            });
        }
        return [];
    };

    useEffect(() => {
        const fetchServicesData = async () => {
            if (serviceId) {
                const currentService = await ServiceAPI.getService(
                    parseInt(serviceId)
                );
                setCurrentService(currentService ?? null);
                setSelectedService(currentService?.service ?? null);
                const files = changeFiles(currentService?.files ?? []);
                setFileList(files ?? []);
            }
            const services = await ServiceAPI.getAllServicesNames();
            setServices(services ?? []);
        };
        fetchServicesData();
    }, [serviceId]);

    const files = [];

    const onFinish = async (values) => {
        for (const file of fileList) {
            if ('originFileObj' in file && file.originFileObj) {
                const base64 = await getBase64(file.originFileObj);
                files.push({ file: base64 });
            }
        }
        const data = {
            car: values.carLicensePlate,
            service: values.serviceName,
            comment: values.serviceComment,
            odometer_indicate: values.odometerIndicate,
            files: files,
            remove_files_ids: removeList,
        };
        currentService &&
            (await ServiceAPI.editService(currentService?.id, data));
        setFileList([]);
        navigate('/user/');
    };

    if (!services || !services.length || !currentService) {
        return <Preloader />;
    }

    const handleRemoveMedia = (media, type) => {
        setAllCaptures((prevMedia) => ({
            ...prevMedia,
            [type]: prevMedia[type].filter((item) => item !== media),
        }));
    };

    const handleCapture = (capture, type) => {
        let lastVideoCount = -5;
        setAllCaptures((prevCaptures) => ({
            ...prevCaptures,
            [type]: [...(prevCaptures[type] || []), capture].slice(
                lastVideoCount
            ),
        }));
    };
    const onCancel = () => {
        setShowCamera(false);
    };
    // const handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     if (file.url && fileFormat(file.url) === 'pdf') {
    //         return openFileInNewTab(file.url);
    //     }
    //     setPreviewImage(file.url || file.preview);
    //     setPreviewOpen(true);
    // };

    // const handleRemove = (file) => {
    //     if (isInteger(file.uid)) {
    //         setRemoveList([file.uid, ...removeList]);
    //     }
    // TODO почему я здесь использовал setFileList
    // setFileList((prevList) =>
    //     prevList.filter((item) => item.uid !== file.uid)
    // );
    // };

    // const handleChange = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    //     form.setFieldsValue({
    //         images: newFileList.map((file) => file.originFileObj),
    //     });
    // };

    return (
        <div className={styles.page__wrapper}>
            <div className={styles.title__wrapper}>
                <h1>Изменение сервиса</h1>
                <span>{currentService.service}</span>
            </div>

            <Form
                form={form}
                onFinish={onFinish}
                className={styles.form}
                initialValues={{
                    carLicensePlate: currentService.car_license_plate,
                    serviceName: serviceNames[1],
                    serviceComment: currentService.comment,
                    odometerIndicate: currentService.odometer_indicate,
                    images: changeFiles(currentService.files ?? []),
                }}
            >
                <h3>Номер автомобиля</h3>
                <Form.Item
                    className={styles.form__item}
                    name="carLicensePlate"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите автомобиль',
                        },
                    ]}
                >
                    <Input size="large" readOnly={true} />
                </Form.Item>
                <h3>Название сервиса</h3>
                <Form.Item
                    className={styles.form__item}
                    name="serviceName"
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста выберите название сервиса.',
                        },
                    ]}
                >
                    <SelectServicesNames services={serviceNames} />
                </Form.Item>
                {(selectedService === CHANGE_OIL ||
                    selectedService === TECHNICAL_INSPECTION) && (
                    <>
                        <h3>Пройденное расстояние(км)</h3>
                        <Form.Item
                            className={styles.form__item}
                            name="odometerIndicate"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Должно быть число от 1 до 1000000',
                                    pattern: new RegExp(/^[0-9]{1,7}$/),
                                },
                            ]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </>
                )}
                <h3>Комментарий</h3>
                <Form.Item className={styles.form__item} name="serviceComment">
                    <TextArea rows={4} autoSize={{ minRows: 4, maxRows: 8 }} />
                </Form.Item>
                <h3>Добавьте фото</h3>
                <Form.Item className={styles.form__item__upload} name="images">
                    <div className={styles.webcam_wrapper}>
                        <WebcamPreviewsList
                            onRemove={handleRemoveMedia}
                            mediaList={allCaptures}
                        />
                        {!showCamera ? (
                            <div className={styles.add_media_button_wrapper}>
                                <button
                                    type="button"
                                    className={styles.add_media_button}
                                    onClick={() => setShowCamera(true)}
                                >
                                    Добавить фото
                                </button>
                            </div>
                        ) : (
                            <WebcamCapture
                                onCancel={onCancel}
                                onCapture={handleCapture}
                                onRemove={handleRemoveMedia}
                                mediaList={allCaptures}
                            />
                        )}
                    </div>
                </Form.Item>
                <Form.Item className={styles.button__item}>
                    <OrangeButton
                        children="Отправить"
                        handleClick={() => onFinish}
                    />
                </Form.Item>
            </Form>
        </div>
    );
};
