import React from 'react';
import styles from './contacts.module.less';

export const Contacts = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.contact}>
                <b>
                    Телефон: <a href="tel:+79539708877">+7 (953) 970-88-77</a>
                </b>
            </div>
            <div className={styles.contact}>
                <b>
                    Телеграмм: <a href="https://t.me/avtocar71">@avtocar71</a>
                </b>
            </div>
            <div className={styles.contact}>
                <b>
                    WhatsApp:{' '}
                    <a
                        href="whatsapp://send?phone=+79509071087"
                        target="_blank"
                    >
                        +7 (950) 907-10-87
                    </a>
                </b>
            </div>
        </div>
    );
};
