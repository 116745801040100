import { FC } from 'react';
import styles from './custon-image.module.less';

interface ICustomImageProps {
    src: string;
    backgroundPosition?: string;
}

export const CustomImage: FC<ICustomImageProps> = ({
    src,
    backgroundPosition = '50% 80%',
}) => {
    return (
        <div
            className={styles.card__image}
            style={{
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundPosition: backgroundPosition,
            }}
        />
    );
};
