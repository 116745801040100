import React from 'react';
import { Button, Tooltip, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

export const CopyButton = ({
    text,
    buttonStyle = {
        marginLeft: '5px',
        color: 'gray',
        backgroundColor: 'white',
    },
    iconStyle = { fontSize: '18px' },
    size = 'small',
}) => {
    const copyToClipboard = (e) => {
        e.stopPropagation();
        navigator.clipboard
            .writeText(text)
            .then(() => {
                message.success('Скопировано в буфер обмена');
            })
            .catch((err) => {
                message.error('Ошибка при копировании');
            });
    };

    return (
        <Tooltip title="Скопировать в буфер обмена">
            <Button
                icon={<CopyOutlined style={iconStyle} />}
                onClick={copyToClipboard}
                style={buttonStyle}
                size={size}
            />
        </Tooltip>
    );
};
